import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import {
  AppBar, Box, Hidden, IconButton, makeStyles, SvgIcon, Theme, Toolbar,
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { NotFound } from 'mediascouting-core-ui-common';
import Account from './Account';
import Logo from '../../../components/common/Logo';
import Notifications from './Notifications';

type TopBarPropTypes = {
  className?: string;
  onMobileNavOpen: () => void;
  logo: string | NotFound;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: 64,
  },
}));

function TopBar(props: TopBarPropTypes): JSX.Element {
  const {
    className, onMobileNavOpen, logo, ...rest
  } = props;
  const classes = useStyles();

  return (
      <AppBar
        className={clsx(classes.root, className)}
        {...rest}
      >
          <Toolbar className={classes.toolbar}>
              <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    onClick={onMobileNavOpen}
                  >
                      <SvgIcon fontSize="small">
                          <MenuIcon />
                      </SvgIcon>
                  </IconButton>
              </Hidden>
              <Hidden mdDown>
                  <RouterLink to="/">
                      <Logo logo={logo} />
                  </RouterLink>
              </Hidden>
              <Box
                ml={2}
                flexGrow={1}
              />
              <Notifications />
              <Box ml={2}>
                  <Account />
              </Box>
          </Toolbar>
      </AppBar>
  );
}

export default TopBar;

import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  getGroupTransformationUtilities,
  GetMyGroupResponse,
  getUserTransformationUtilities,
  Group,
  LoginResponse,
  onFeedback,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
  UserDTO,
} from 'mediascouting-core-ui-common';
import initialAxiosInstance, { CancelToken } from 'axios';
import axios from '../utils/Http';
import {
  sessionLoginAction,
  sessionLogoutAction,
  setUserGroup,
  userDataAction,
} from '../constants/actions/creators/AuthActions';
import authService from '../services/authService';
import { getRoles } from './Role';
import getPowerSearchParams from './PowerSearch';
import { getGroups } from './Group';
import getApplicationProperties from './ApplicationProperties';

export const getMyGroup = (
  cancelToken?: CancelToken,
) => (dispatch): Promise<GetMyGroupResponse> => axios.get<GetMyGroupResponse>('auth/portal/my-group', {
  cancelToken,
})
  .then(({ data }) => {
    const group: Group = getGroupTransformationUtilities().transform().fromDto(data?.group || {});

    dispatch(setUserGroup(group));

    return data;
  })
  .catch((error) => Promise.reject(error));

export const getUser = () => (dispatch): Promise<void> => axios.get<UserDTO>('auth/admin/me')
  .then(({ data }) => {
    if (data) {
      const user = getUserTransformationUtilities().transform().fromDto(data);

      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
        .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
        .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_USER]}`)
        .setNotifyUser(false)
        .build()));

      dispatch(userDataAction(user));
    }

    dispatch(getRoles());
    dispatch(getGroups());
    dispatch(getMyGroup());
    dispatch(getApplicationProperties());
    dispatch(getPowerSearchParams());
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_USER]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const login = (
  username,
  password,
) => (dispatch): Promise<void> => axios.post<LoginResponse>('auth/admin/login', { username, password })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.LOGIN]}`)
      .setNotifyUser(false)
      .build()));

    dispatch(sessionLoginAction({ token: data.access_token }));
    authService.setSession(data.access_token);
  })
  .then(() => getUser()(dispatch))
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.LOGIN]}`)
        .setNotifyUser(true)
        .build()));
    }

    return Promise.reject(error);
  });

export const logoutAfterExpiredAccess = () => (dispatch): void => {
  authService.logout();
  dispatch(sessionLogoutAction());
};

export const logout = () => (dispatch): Promise<void> => axios.post<void>('auth/admin/logout')
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.LOGOUT]}`)
      .setNotifyUser(false)
      .build()));

    return data;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.LOGOUT]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  })
  .finally(() => {
    authService.logout();
    dispatch(sessionLogoutAction());
  });

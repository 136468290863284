import React, { ReactNode, useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { getLogoValueFromRedux, LogoStorage, LogoTypeEnum } from 'mediascouting-core-ui-common';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../redux/reducers';
import TopBar from '../AdminLayout/TopBar';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#f4f6f8',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  content: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    paddingTop: 80,
    margin: 'auto',
    paddingLeft: 40,
  },
}));

interface TemplateEditorLayoutPropTypes {
    children: ReactNode;
}

function TemplateEditorLayout(props: TemplateEditorLayoutPropTypes): JSX.Element {
  const classes = useStyles();
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const reduxLogos: Array<LogoStorage> = useSelector((state: ReduxState) => state.logoStorage.logos);
  const logo = getLogoValueFromRedux(
    reduxLogos,
    LogoTypeEnum.ADMIN,
  );

  const handleOnMobileNav = useCallback((): void => {
    setMobileNavOpen(true);
  }, []);

  return (
      <div className={classes.root}>
          <TopBar
            onMobileNavOpen={handleOnMobileNav}
            logo={logo}
          />
          <div className={classes.content}>
              {children}
          </div>
      </div>
  );
}

export default TemplateEditorLayout;

import {
  AlertConfigurationSchema, ConfigurationSchema, FeedsConfigurationSchema, LoggingSchema,
} from '../schema';

const DEFAULT_ALERT_CONFIGURATION: AlertConfigurationSchema = {
  reportOptions: {
    disabled: [],
    defaults: {},
  },
};

const DEFAULT_LOGGING_CONFIGURATION: LoggingSchema = {
  activeFeedbackTypes: [],
};

const DEFAULT_FEEDS_CONFIGURATION: FeedsConfigurationSchema = {
  netFeeder: {
    metadata: {
      platformOptions: {
        disabled: [],
      },
    },
  },
};

const DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA: ConfigurationSchema = {
  alerts: DEFAULT_ALERT_CONFIGURATION,
  logging: DEFAULT_LOGGING_CONFIGURATION,
  feeds: DEFAULT_FEEDS_CONFIGURATION,
};

export default DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;

import React from 'react';
import {
  Box, CircularProgress, makeStyles, Theme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLogoValueFromRedux, LogoStorage, LogoTypeEnum } from 'mediascouting-core-ui-common';
import Logo from './Logo';
import { ReduxState } from '../../redux/reducers';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    padding: theme.spacing(3),
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2000,
  },
  logo: {
    width: 200,
    maxWidth: '100%',
  },
}));

function SlashScreen(): JSX.Element {
  const classes = useStyles();
  const reduxLogos: Array<LogoStorage> = useSelector((state: ReduxState) => state.logoStorage.logos);
  const logo = getLogoValueFromRedux(
    reduxLogos,
    LogoTypeEnum.DEFAULT,
  );

  return (
      <div className={classes.root}>
          <Box
            display="flex"
            justifyContent="center"
            mb={6}
          >
              <Logo logo={logo} />
          </Box>
          <CircularProgress />
      </div>
  );
}

export default SlashScreen;

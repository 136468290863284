import { getUrlAddressFromEnv, NotFound } from 'mediascouting-core-ui-common';
import { PlatformConfiguration } from '../types/Configuration';
import DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA from './defaults';
import { ConfigurationSchema } from './schema';

const getClientSchema = (): ConfigurationSchema => {
  const client: string | NotFound = process.env.REACT_APP_CLIENT;
  if (client) {
    try {
      const customSchema = require(`./overrides/${client}/index`)
        .default as ConfigurationSchema;
      return { ...DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA, ...customSchema };
    } catch (error) {
      return DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;
    }
  }
  return DEFAULT_USER_INTERFACE_CONFIGURATION_SCHEMA;
};

const Configuration: PlatformConfiguration = {
  apiAddress: getUrlAddressFromEnv(process.env.REACT_APP_API_ADDRESS),
  graylogAddress: getUrlAddressFromEnv(process.env.REACT_APP_GRAYLOG_ADDRESS),
  redmineAddress: getUrlAddressFromEnv(process.env.REACT_APP_REDMINE_ADDRESS),
  portalAddress: getUrlAddressFromEnv(process.env.REACT_APP_PORTAL_ADDRESS),
  appAddress: process.env.REACT_APP_ADDRESS,
  defaultLocale: process.env.REACT_APP_DEFAULT_LOCALE,
  defaultFallbackLocale: process.env.REACT_APP_DEFAULT_FALLBACK_LOCALE,
  defaultLogoPath: process.env.REACT_APP_DEFAULT_LOGO_PATH,
  schema: getClientSchema(),
};

export default Configuration;

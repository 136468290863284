import React, { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar, Badge, Box, ButtonBase, Hidden, makeStyles, Menu, MenuItem, Theme, Tooltip, Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { deepPurple } from '@material-ui/core/colors';
import {
  User, AvatarBadge, NotFound, NOT_FOUND,
} from 'mediascouting-core-ui-common';
import { ReduxState } from '../../../redux/reducers';
import { AppDispatch } from '../../../redux/store';
import { logout } from '../../../remote/Auth';
import getInitials from '../../../utils/getInitials';
import { stopImpersonating } from '../../../remote/Impersonate';

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    marginRight: theme.spacing(1),
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  popover: {
    width: 200,
  },
  name: {
    width: 'max-content',
  },
}));

function Account(): JSX.Element {
  const classes = useStyles();
  const ref = useRef(null);
  const { t } = useTranslation();
  const { user } = useSelector((state: ReduxState) => state.auth);
  const [isOpen, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleStopImpersonation = () => (): void => {
    dispatch(stopImpersonating())
      .finally(() => {
        window.location.reload();
      });
  };

  const getAvatar = (loggedInUser: User): JSX.Element => {
    if (loggedInUser?.impersonatedBy) {
      return (
          <>
              <Badge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={(
                    <Tooltip title={loggedInUser?.impersonatedBy?.fullName}>
                        <AvatarBadge alt="Original User" className={classes.avatar}>
                            <Typography variant="subtitle2">
                                {getInitials(loggedInUser?.impersonatedBy?.fullName)}
                            </Typography>
                        </AvatarBadge>
                    </Tooltip>
                )}
              >
                  <Tooltip title={loggedInUser?.fullName}>
                      <Avatar alt="Impersonated User" className={classes.avatar}>
                          <Typography variant="subtitle2">
                              {getInitials(loggedInUser.fullName)}
                          </Typography>
                      </Avatar>
                  </Tooltip>
              </Badge>
              <Hidden smDown>
                  <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.name}
                  >
                      {`${loggedInUser.fullName}`}
                  </Typography>
              </Hidden>
          </>
      );
    }

    return (
        <>
            <Tooltip title={loggedInUser?.fullName}>
                <Avatar alt="User" className={classes.avatar}>
                    <Typography variant="subtitle2">
                        {getInitials(loggedInUser.fullName)}
                    </Typography>
                </Avatar>
            </Tooltip>
            <Hidden smDown>
                <Typography
                  variant="h6"
                  color="inherit"
                  className={classes.name}
                >
                    {`${loggedInUser.fullName}`}
                </Typography>
            </Hidden>
        </>

    );
  };

  const getStopImpersonatingMenuItem = (): JSX.Element | NotFound => {
    if (user?.impersonatedBy) {
      return (
          <MenuItem onClick={handleStopImpersonation()}>
              Stop Impersonating
          </MenuItem>
      );
    }

    return NOT_FOUND;
  };

  return (
      <>
          {user
      && (
          <div ref={ref}>
              <Box
                display="flex"
                alignItems="center"
                component={ButtonBase}
                onClick={handleOpen}
              >
                  {getAvatar(user)}
              </Box>
          </div>
      )}
          <Menu
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            keepMounted
            PaperProps={{ className: classes.popover }}
            getContentAnchorEl={null}
            anchorEl={ref.current}
            open={isOpen}
          >
              <MenuItem
                component={RouterLink}
                to="/account"
              >
                  {t('topBar.account')}
              </MenuItem>
              {getStopImpersonatingMenuItem()}
              <MenuItem onClick={(): Promise<void> => dispatch(logout())}>
                  {t('topBar.account.actions.logout')}
              </MenuItem>
          </Menu>
      </>
  );
}

export default Account;

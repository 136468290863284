import { MultivaluedPowerSearchQueryFilters, PowerSearchFilterTypes } from 'mediascouting-core-ui-common';
import { PowerSearchActions } from '../../constants/actions/creators/PowerSearchActions';
import {
  ADD_TO_POWER_SEARCH_BUCKET,
  DELETE_POWER_SEARCH_TAG,
  SET_POWER_SEARCH_PARAMS,
} from '../../constants/actions/types/PowerSearchActionTypes';

export interface PowerSearchOptions extends MultivaluedPowerSearchQueryFilters {

}

const isBucketUpdatableType = (type: PowerSearchFilterTypes): boolean => type === PowerSearchFilterTypes.MARKETS
    || type === PowerSearchFilterTypes.SOURCES
    || type === PowerSearchFilterTypes.SUBQUERIES
    || type === PowerSearchFilterTypes.PEOPLE
    || type === PowerSearchFilterTypes.GEOLOCATION_TAGS
    || type === PowerSearchFilterTypes.ORGANIZATIONS;

const INIT_STATE: PowerSearchOptions = {
  [PowerSearchFilterTypes.DAYS]: [],
  [PowerSearchFilterTypes.TIME_SECTIONS]: [],
  [PowerSearchFilterTypes.SENTIMENTS]: [],
  [PowerSearchFilterTypes.LANGUAGES]: [],
  [PowerSearchFilterTypes.SOURCE_TYPES]: [],
  [PowerSearchFilterTypes.TAGS]: [],
  [PowerSearchFilterTypes.ORGANIZATIONS]: [],
  [PowerSearchFilterTypes.GEOLOCATION_TAGS]: [],
  [PowerSearchFilterTypes.PEOPLE]: [],
  [PowerSearchFilterTypes.SUBQUERIES]: [],
  [PowerSearchFilterTypes.SOURCES]: [],
  [PowerSearchFilterTypes.SOURCE_GROUPS]: [],
  [PowerSearchFilterTypes.MARKETS]: [],
  [PowerSearchFilterTypes.MARKET_GROUPS]: [],
  [PowerSearchFilterTypes.AUTHORS]: [],
  [PowerSearchFilterTypes.STORY_DURATION]: [],
  [PowerSearchFilterTypes.ORIGIN]: [],
};

export default (state = INIT_STATE, action: PowerSearchActions): PowerSearchOptions => {
  switch (action.type) {
    case SET_POWER_SEARCH_PARAMS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ADD_TO_POWER_SEARCH_BUCKET: {
      try {
        const { type, data } = action.payload;
        const options = state[type];

        if (options && isBucketUpdatableType(type)) {
          const found = options.find((searchableOption) => searchableOption.id === data.id);

          if (!found) {
            const copiedStateFilterOptions = [...options, data];

            return {
              ...state,
              [type]: copiedStateFilterOptions,
            };
          }
        }
      } catch {
        return {
          ...state,
        };
      }

      return {
        ...state,
      };
    }
    case DELETE_POWER_SEARCH_TAG: {
      const updatedTags = [...state[PowerSearchFilterTypes.TAGS]];
      const foundIndex = state[PowerSearchFilterTypes.TAGS]
        .findIndex((searchableTag) => searchableTag.id === action.payload);

      if (foundIndex >= 0) {
        updatedTags.splice(foundIndex, 1);
      }

      return {
        ...state,
        [PowerSearchFilterTypes.TAGS]: updatedTags,
      };
    }
    default:
      return state;
  }
};

import initialAxiosInstance, { CancelToken } from 'axios';
import {
  ApplicationProperties,
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetApplicationPropertiesResponse,
  getApplicationPropertiesTransformationUtilities,
  onFeedback,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum, setApplicationProperties,
} from 'mediascouting-core-ui-common';
import axios from '../utils/Http';

const getApplicationProperties = (
  params?: object, cancelToken?: CancelToken,
) => (dispatch): Promise<ApplicationProperties> => axios
  .get<GetApplicationPropertiesResponse>('/application-properties', {
    params,
    cancelToken,
  })
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_APPLICATION_PROPERTIES]}`)
      .setNotifyUser(false)
      .build()));

    const properties = getApplicationPropertiesTransformationUtilities().transform().fromDto(data);
    dispatch(setApplicationProperties(properties));

    return properties;
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_APPLICATION_PROPERTIES]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export default getApplicationProperties;

import { NOT_FOUND } from 'mediascouting-core-ui-common';
import { EMPTY_STRING } from '../../constants/Common';
import {
  COMPLETE_GROUP_WIZARD,
  GROUP_WIZARD_FAILURE,
  GroupDetailsPayload,
  NEXT_GROUP_WIZARD_STEP,
  PREVIOUS_GROUP_WIZARD_STEP,
  SAVE_WIZARD_DEPARTMENT_PARENT,
  SAVE_WIZARD_GROUP_DETAILS,
  SAVE_WIZARD_GROUP_TYPE,
} from '../../constants/actions/types/GroupWizardActionTypes';
import { GroupWizardActions } from '../../constants/actions/creators/GroupWizardActions';

export type GroupWizardReducer = {
    activeStep: number;
    submit: boolean;
    error: boolean;
    groupTypeId?: number;
    parentId?: number;
    groupDetails: GroupDetailsPayload;
};

const INIT_STATE: GroupWizardReducer = {
  activeStep: 0,
  submit: false,
  error: false,
  groupDetails: {
    name: EMPTY_STRING,
    description: EMPTY_STRING,
  },
};

export default (state = INIT_STATE, action: GroupWizardActions): GroupWizardReducer => {
  switch (action.type) {
    case NEXT_GROUP_WIZARD_STEP: {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }
    case PREVIOUS_GROUP_WIZARD_STEP: {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }
    case COMPLETE_GROUP_WIZARD: {
      return {
        ...state,
        activeStep: 0,
        submit: false,
        error: false,
        groupTypeId: NOT_FOUND,
        groupDetails: {
          name: EMPTY_STRING,
          description: EMPTY_STRING,
        },
      };
    }
    case SAVE_WIZARD_GROUP_TYPE: {
      return {
        ...state,
        groupTypeId: action.payload,
      };
    }
    case SAVE_WIZARD_DEPARTMENT_PARENT: {
      return {
        ...state,
        parentId: action.payload,
      };
    }
    case SAVE_WIZARD_GROUP_DETAILS: {
      return {
        ...state,
        groupDetails: action.payload,
        submit: true,
      };
    }
    case GROUP_WIZARD_FAILURE: {
      return {
        ...state,
        error: true,
        submit: false,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import { useSelector } from 'react-redux';
import { User } from 'mediascouting-core-ui-common';
import { ReduxState } from '../../redux/reducers';

interface VisiblePropTypes {
    when: (user: User) => boolean;
    children: React.ReactNode;
}

function Visible(props: VisiblePropTypes): JSX.Element {
  const { when, children } = props;
  const user = useSelector((state: ReduxState) => state.auth.user);

  return (
      <>
          {Boolean(user && when(user)) && children}
      </>
  );
}

export default Visible;

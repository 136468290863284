import React, {
  useCallback, useEffect, useMemo, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  constructDescriptiveMessage,
  constructGraylogEntity,
  DescriptiveMessage,
  FeedbackLoggerMessage,
  FeedbackType,
  NotFound,
  NotificationTypeEnum,
  SnackbarTypeEnum,
  useNotifyUser,
  EMPTY_STRING,
  removeUserMessageFeedback,
} from 'mediascouting-core-ui-common';
import { useTranslation } from 'react-i18next';
import { ReduxState } from '../../../redux/reducers';
import { AppDispatch } from '../../../redux/store';
import sendLog from '../../../remote/Logs';
import TranslationNameSpaceEnum from '../../../types/TranslationNameSpaceEnum';

function FeedbackCentralAgent(): JSX.Element {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation(TranslationNameSpaceEnum.REMOTE);
  const userMessages = useSelector((state: ReduxState) => state.feedbackLogger.userMessages);
  const handledUserMessagesRef = useRef<Array<FeedbackLoggerMessage>>([]);
  const logs = useSelector((state: ReduxState) => state.feedbackLogger.logs);
  const handledLogsRef = useRef<Array<FeedbackLoggerMessage>>([]);
  const { sendNotification, sendAlert } = useNotifyUser();

  const notifyUser = useCallback((
    descriptiveMessage: DescriptiveMessage,
    snackbarType: SnackbarTypeEnum,
    notificationType?: NotificationTypeEnum,
  ): void => {
    sendAlert(descriptiveMessage, snackbarType);
    if (notificationType) {
      dispatch(sendNotification(descriptiveMessage, notificationType));
    }
  }, [dispatch, sendAlert, sendNotification]);

  const handleRemoteSystemFailure = useCallback((feedback: FeedbackLoggerMessage): void => {
    if (feedback.notifyUser) {
      notifyUser(constructDescriptiveMessage(feedback, t), SnackbarTypeEnum.ERROR, NotificationTypeEnum.ERROR);
    }
  }, [notifyUser, t]);

  const handleRemoteSystemSuccess = useCallback((feedback: FeedbackLoggerMessage): void => {
    if (feedback.notifyUser) {
      notifyUser(constructDescriptiveMessage(feedback, t), SnackbarTypeEnum.SUCCESS);
    }
  }, [notifyUser, t]);

  const handleLogMessage = useCallback((feedback: FeedbackLoggerMessage): void => {
    dispatch(sendLog(constructGraylogEntity(feedback)));
  }, [dispatch]);

  const handleUserActionFailure = useCallback((feedback: FeedbackLoggerMessage): void => {
    const descriptiveMessage: DescriptiveMessage = {
      title: t(feedback.userMessage || EMPTY_STRING),
      description: t(feedback.userMessage || EMPTY_STRING),
    };
    notifyUser(descriptiveMessage, SnackbarTypeEnum.ERROR, NotificationTypeEnum.ERROR);

    dispatch(removeUserMessageFeedback(feedback.uuid));
  }, [dispatch, notifyUser, t]);

  const userMessageHandlerDecider = useMemo(() => ({
    [FeedbackType.REMOTE_SYSTEM_FAILURE]: handleRemoteSystemFailure,
    [FeedbackType.REMOTE_SYSTEM_SUCCESS]: handleRemoteSystemSuccess,
    [FeedbackType.USER_ACTION_FAILURE]: handleUserActionFailure,
  }), [handleRemoteSystemFailure, handleRemoteSystemSuccess, handleUserActionFailure]);

  const handleLatestUserMessage = useCallback(() => {
    userMessages.forEach((userMessage) => {
      const foundRefUserMessage = handledUserMessagesRef.current
        .find((refUserMessage) => refUserMessage.uuid === userMessage.uuid);

      if (!foundRefUserMessage) {
        const handlerFunction: ((feedback: FeedbackLoggerMessage) => void)
            | NotFound = userMessageHandlerDecider[userMessage.feedbackType];

        if (handlerFunction) {
          handlerFunction(userMessage);
          handledUserMessagesRef.current.push(userMessage);
        }
      }
    });
  }, [userMessages, userMessageHandlerDecider]);

  const handleLatestLogMessage = useCallback(() => {
    logs.forEach((log) => {
      const foundRefLog = handledLogsRef.current.find((refLog) => refLog.uuid === log.uuid);

      if (!foundRefLog) {
        handleLogMessage(log);
        handledLogsRef.current.push(log);
      }
    });
  }, [handleLogMessage, logs]);

  useEffect(() => {
    handleLatestUserMessage();
  }, [handleLatestUserMessage]);

  useEffect(() => {
    handleLatestLogMessage();
  }, [handleLatestLogMessage]);

  return (
      <>
      </>
  );
}

export default FeedbackCentralAgent;

enum TranslationNameSpaceEnum{
    DEFAULT = 'translation',
    REMOTE = 'remote',
    TEMPLATES = 'templates',
    MARKETS = 'markets',
    SOURCE_GROUP = 'sourceGroups',
    FEEDS = 'feeds'
}

export default TranslationNameSpaceEnum;

import initialAxiosInstance, { CancelToken } from 'axios';
import {
  buildFeedbackMessage,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  getDataImageString,
  LogoTypeEnum,
  onFeedback,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';
import axios from '../utils/Http';

const getUserLogo = (
  logoType?: LogoTypeEnum, cancelToken?: CancelToken,
) => (dispatch): Promise<string> => axios.get<string>('/logo', {
  params: {
    type: logoType,
  },
  responseType: 'arraybuffer',
  cancelToken,
})
  .then((response) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_USER_LOGO]}`)
      .setNotifyUser(false)
      .build()));
    return getDataImageString(response.data, response?.headers['content-type']);
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_USER_LOGO]}`)
        .setNotifyUser(false)
        .build()));
    }
    return Promise.reject(error);
  });

export default getUserLogo;

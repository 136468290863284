import { NullableNumber } from 'mediascouting-core-ui-common';
import {
  COMPLETE_USER_WIZARD,
  NEXT_USER_WIZARD_STEP,
  PREVIOUS_USER_WIZARD_STEP,
  SAVE_WIZARD_USER_DETAILS,
  SAVE_WIZARD_USER_GROUP_AND_ROLE,
  USER_WIZARD_FAILURE,
  UserDetailsPayload,
} from '../../constants/actions/types/UserWizardActionTypes';
import { UserWizardActions } from '../../constants/actions/creators/UserWizardActions';
import { EMPTY_STRING } from '../../constants/Common';

export type UserWizardReducer = {
    activeStep: number;
    submit: boolean;
    error: boolean;
    roleId: NullableNumber;
    groupId: NullableNumber;
    userDetails: UserDetailsPayload;
};

const INIT_STATE: UserWizardReducer = {
  activeStep: 0,
  submit: false,
  error: false,
  roleId: null,
  groupId: null,
  userDetails: {
    username: EMPTY_STRING,
    fullName: EMPTY_STRING,
    email: EMPTY_STRING,
    password: EMPTY_STRING,
  },
};

export default (state = INIT_STATE, action: UserWizardActions): UserWizardReducer => {
  switch (action.type) {
    case NEXT_USER_WIZARD_STEP: {
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    }
    case PREVIOUS_USER_WIZARD_STEP: {
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    }
    case COMPLETE_USER_WIZARD: {
      return {
        ...state,
        activeStep: 0,
        submit: false,
        error: false,
        roleId: null,
        groupId: null,
        userDetails: {
          username: EMPTY_STRING,
          fullName: EMPTY_STRING,
          email: EMPTY_STRING,
          password: EMPTY_STRING,
        },
      };
    }
    case SAVE_WIZARD_USER_GROUP_AND_ROLE: {
      return {
        ...state,
        roleId: action.payload.roleId,
        groupId: action.payload.groupId,
      };
    }
    case SAVE_WIZARD_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
        submit: true,
      };
    }
    case USER_WIZARD_FAILURE: {
      return {
        ...state,
        error: true,
        submit: false,
      };
    }
    default:
      return state;
  }
};

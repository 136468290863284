import axios from 'axios';
import Configuration from '../configuration/Configuration';

const http = axios.create({
  baseURL: Configuration.apiAddress,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default http;

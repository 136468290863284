export const NEXT_GROUP_WIZARD_STEP = 'NEXT_GROUP_WIZARD_STEP';
export const PREVIOUS_GROUP_WIZARD_STEP = 'PREVIOUS_GROUP_WIZARD_STEP';
export const COMPLETE_GROUP_WIZARD = 'COMPLETE_GROUP_WIZARD';
export const GROUP_WIZARD_FAILURE = 'GROUP_WIZARD_FAILURE';
export const SAVE_WIZARD_GROUP_TYPE = 'SAVE_WIZARD_GROUP_TYPE';
export const SAVE_WIZARD_DEPARTMENT_PARENT = 'SAVE_WIZARD_DEPARTMENT_PARENT';
export const SAVE_WIZARD_GROUP_DETAILS = 'SAVE_WIZARD_GROUP_DETAILS';

export type GroupDetailsPayload = {
    name: string;
    description: string;
}

import { PowerSearchFilterTypes, Shallow } from 'mediascouting-core-ui-common';

export const SET_POWER_SEARCH_PARAMS = 'SET_POWER_SEARCH_PARAMS';
export const ADD_TO_POWER_SEARCH_BUCKET = 'ADD_TO_POWER_SEARCH_BUCKET';
export const SET_POWER_SEARCH_TAGS = 'SET_POWER_SEARCH_TAGS';
export const CREATE_POWER_SEARCH_TAG = 'CREATE_POWER_SEARCH_TAG';
export const UPDATE_POWER_SEARCH_TAG = 'UPDATE_POWER_SEARCH_TAG';
export const DELETE_POWER_SEARCH_TAG = 'DELETE_POWER_SEARCH_TAG';

export type PowerSearchBucketUpdate = {
    type: PowerSearchFilterTypes;
    data: Shallow;
};

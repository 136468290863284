import { CombinedState, combineReducers } from 'redux';
import {
  ApplicationProperties,
  FeedbackLogger,
  FeedbackLoggerReducer,
  LogoStorageReducer,
  NotificationReducer,
  ApplicationPropertiesReducer,
  NOT_FOUND,
} from 'mediascouting-core-ui-common';
import { SESSION_LOGOUT } from '../../constants/actions/types/AuthActionTypes';
import PowerSearchOptions, { PowerSearchOptions as PowerSearchOptionsReducer } from './PowerSearchOptions';
import GroupWizard, { GroupWizardReducer } from './GroupWizard';
import UserWizard, { UserWizardReducer } from './UserWizard';
import Auth, { AuthReducer } from './Auth';

interface ReduxReducers {
  auth: AuthReducer;
  userWizard: UserWizardReducer;
  groupWizard: GroupWizardReducer;
  powerSearchOptions: PowerSearchOptionsReducer;
  notifications: NotificationReducer;
  feedbackLogger: FeedbackLoggerReducer;
  logoStorage: LogoStorageReducer;
  applicationProperties: ApplicationProperties;
}

const appReducer = combineReducers({
  auth: Auth,
  userWizard: UserWizard,
  groupWizard: GroupWizard,
  powerSearchOptions: PowerSearchOptions,
  applicationProperties: ApplicationPropertiesReducer,
  feedbackLogger: FeedbackLogger,
  notifications: NotificationReducer,
  logoStorage: LogoStorageReducer,
});

const LOCAL_STORAGE_REDUCER_KEY = 'logoStorage';
const rootReducer = (state, action) => {
  if (action.type === SESSION_LOGOUT) {
    const newState = Object.keys(state)
      .filter((key) => key === LOCAL_STORAGE_REDUCER_KEY)
      .reduce((obj, key) => ({ ...obj, [key]: state[key] }), {});

    if (newState as CombinedState<ReduxReducers>) {
      return appReducer(newState as CombinedState<ReduxReducers>, action);
    }
    return appReducer(NOT_FOUND, action);
  }

  return appReducer(state, action);
};

export type ReduxState = ReturnType<typeof rootReducer>

export default rootReducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { ReduxState } from '../../redux/reducers';

type AuthGuardPropTypes = {
  children: React.ReactNode;
};

function AuthGuard(props: AuthGuardPropTypes): React.ReactNode {
  const { children } = props;
  const user = useSelector((state: ReduxState) => state.auth.user);

  if (!user) {
    return <Redirect to="/auth/login" />;
  }

  return children;
}

export default AuthGuard;

import {
  buildFeedbackMessage,
  convertResponseIntoPowerSearchModel,
  DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE,
  FeedbackType,
  GetPowerSearchOptionsResponse,
  onFeedback,
  REMOTE_FAILURE_MESSAGES,
  REMOTE_SUCCESS_MESSAGES,
  RemoteMessagesEnum,
} from 'mediascouting-core-ui-common';

import initialAxiosInstance from 'axios';
import { setPowerSearchParams } from '../constants/actions/creators/PowerSearchActions';
import axios from '../utils/Http';

const getPowerSearchParams = () => (dispatch): Promise<void> => axios
  .get<GetPowerSearchOptionsResponse>('/power-search-params')
  .then(({ data }) => {
    dispatch(onFeedback(buildFeedbackMessage()
      .setFeedbackType(FeedbackType.REMOTE_SYSTEM_SUCCESS)
      .setSystemMessage(DEFAULT_REMOTE_SUCCESS_SYSTEM_MESSAGE)
      .setUserMessage(`${REMOTE_SUCCESS_MESSAGES[RemoteMessagesEnum.GET_POWER_SEARCH_OPTIONS]}`)
      .setNotifyUser(false)
      .build()));

    const powerSearchParams = convertResponseIntoPowerSearchModel(data);

    dispatch(setPowerSearchParams(powerSearchParams));
  })
  .catch((error) => {
    if (!initialAxiosInstance.isCancel(error)) {
      dispatch(onFeedback(buildFeedbackMessage()
        .setFeedbackType(FeedbackType.REMOTE_SYSTEM_FAILURE)
        .setSystemMessage(error)
        .setUserMessage(`${REMOTE_FAILURE_MESSAGES[RemoteMessagesEnum.GET_POWER_SEARCH_OPTIONS]}`)
        .setNotifyUser(false)
        .build()));
    }

    return Promise.reject(error);
  });

export default getPowerSearchParams;

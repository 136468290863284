import React, { memo } from 'react';
import {
  Box, Divider, makeStyles, Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
}));

function Header(): JSX.Element {
  const classes = useStyles();

  return (
      <Box>
          <Box p={2}>
              <Typography variant="h5" color="textPrimary">
                  Notifications
              </Typography>
          </Box>
          <Divider light />
      </Box>
  );
}

export default memo(Header);

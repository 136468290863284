import React, {
  memo, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  IconButton, makeStyles, Popover, Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import { markAllNotificationsAsRead, Notification, StyledPurpleBadge } from 'mediascouting-core-ui-common';
import { ReduxState } from '../../../../redux/reducers';
import { AppDispatch } from '../../../../redux/store';
import EmptyScreen from './EmptyScreen';
import Header from './Header';
import NotificationList from './NotificationList';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 380,
  },
  successIcon: {
    backgroundColor: theme.palette.success.main,
    color: '#fff',
  },
  infoIcon: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  errorIcon: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function Notifications(): JSX.Element {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const notifications = useSelector((state: ReduxState) => state.notifications.notifications);
  const [isOpen, setOpen] = useState(false);
  const [unread, setUnread] = useState(0);

  const handleOpen = (): void => {
    setOpen(true);
    dispatch(markAllNotificationsAsRead());
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleNotificationClick = useCallback((notification: Notification): void => {
    if (notification?.onClick) {
      notification.onClick();
    }

    handleClose();
  }, []);

  useEffect(() => {
    const unreadNotifications = notifications.filter(((value) => !value.read));

    setUnread(unreadNotifications.length);
  }, [notifications]);

  return (
      <>
          <Tooltip title="Notifications">
              <IconButton
                color="inherit"
                ref={ref}
                onClick={handleOpen}
              >
                  <StyledPurpleBadge badgeContent={unread}>
                      <NotificationsRoundedIcon />
                  </StyledPurpleBadge>
              </IconButton>
          </Tooltip>
          <Popover
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            classes={{ paper: classes.popover }}
            anchorEl={ref.current}
            onClose={handleClose}
            open={isOpen}
          >
              <Header />
              {notifications.length === 0 ? <EmptyScreen /> : <NotificationList onClick={handleNotificationClick} /> }
          </Popover>
      </>
  );
}

export default memo(Notifications);

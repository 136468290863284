import React, { ReactNode, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLogoValueFromRedux, LogoStorage, LogoTypeEnum } from 'mediascouting-core-ui-common';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { ReduxState } from '../../redux/reducers';

type AdminLayoutPropTypes = {
  children: ReactNode;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#f4f6f8',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}));

function AdminLayout(props: AdminLayoutPropTypes): JSX.Element {
  const classes = useStyles();
  const { children } = props;
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const reduxLogos: Array<LogoStorage> = useSelector((state: ReduxState) => state.logoStorage.logos);
  const logo = getLogoValueFromRedux(
    reduxLogos,
    LogoTypeEnum.ADMIN,
  );
  return (
      <div className={classes.root}>
          <TopBar
            className=""
            onMobileNavOpen={(): void => setMobileNavOpen(true)}
            logo={logo}
          />
          <NavBar
            onMobileClose={(): void => setMobileNavOpen(false)}
            openMobile={isMobileNavOpen}
            logo={logo}
          />
          <div className={classes.wrapper}>
              <div className={classes.contentContainer}>
                  <div className={classes.content}>
                      {children}
                  </div>
              </div>
          </div>
      </div>
  );
}

export default AdminLayout;

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'nprogress/nprogress.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import CacheBuster from 'react-cache-buster';
import * as serviceWorker from './serviceWorker';
import configureStore from './redux/store';
import App from './App';
import LoadingScreen from './components/common/LoadingScreen';
import { version } from '../package.json';

const store = configureStore();
const handleCacheClear = (refreshFunction: () => Promise<void>): void => {
  if (refreshFunction) {
    refreshFunction()
      .then(() => {
        console.log('Cache cleared successfully!');
      })
      .catch(() => {
        console.log('Could not clear cache!');
      });
  }
};

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <Suspense fallback={<LoadingScreen />}>
                <CacheBuster
                  currentVersion={version}
                  isVerboseMode
                  loadingComponent={<LoadingScreen />}
                  metaFileDirectory="."
                  isEnabled
                  onCacheClear={handleCacheClear}
                >
                    <App />
                </CacheBuster>
            </Suspense>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

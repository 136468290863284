/* eslint-disable no-use-before-define */
import React from 'react';
import { matchPath, useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  EMPTY_STRING, hasPermission, NotFound, User,
} from 'mediascouting-core-ui-common';
import { useSelector } from 'react-redux';
import { deepPurple } from '@material-ui/core/colors';
import { Theme } from '@material-ui/core/styles';
import NavItem from './NavItem';
import Logo from '../../../components/common/Logo';
import getNavigationConfiguration, { NavConfigItem } from './constants/NavigationConfig';
import Visible from '../../../components/common/Visible';
import { ReduxState } from '../../../redux/reducers';
import getInitials from '../../../utils/getInitials';

type NavBarPropTypes = {
  onMobileClose: () => void;
  openMobile: boolean;
  logo: string | NotFound;
};

type NavBlock = {
    acc: Array<React.ReactNode>;
    item: NavConfigItem;
    pathname: string;
    depth: number;
}

function renderNavItems(items: Array<NavConfigItem>, pathname: string, depth: number): JSX.Element {
  return (
      <List disablePadding>
          {items.reduce<Array<React.ReactNode>>((previousValue, currentValue) => reduceChildRoutes(
            {
              acc: previousValue,
              item: currentValue,
              pathname,
              depth,
            },
          ),
          [])}
      </List>
  );
}

function reduceChildRoutes(
  {
    acc, item, pathname, depth = 0,
  }: NavBlock,
): Array<React.ReactNode> {
  const key = item.title + depth;
  const permissionsValid = (user: User): boolean => {
    if (item.permissions.length > 0) {
      return item.permissions.some((s) => hasPermission(s)(user));
    }

    return true;
  };

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
        <Visible key={key} when={permissionsValid}>
            <NavItem
              depth={depth}
              icon={item.icon}
              key={key}
              href={item.href}
              info={item.info}
              open={Boolean(open)}
              title={item.title}
            >
                {renderNavItems(item.items, pathname, depth + 1)}
            </NavItem>
        </Visible>
      ,
    );
  } else {
    acc.push(
        <Visible key={key} when={permissionsValid}>
            <NavItem
              depth={depth}
              href={item.href}
              icon={item.icon}
              key={key}
              info={item.info}
              title={item.title}
              open={false}
            />
        </Visible>,
    );
  }

  return acc;
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
}));

function NavBar(props: NavBarPropTypes): JSX.Element {
  const { openMobile, onMobileClose, logo } = props;
  const classes = useStyles();
  const location = useLocation();
  const user = useSelector((reduxState: ReduxState) => reduxState.auth.user);

  const content = (
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
      >
          <PerfectScrollbar options={{ suppressScrollX: true }}>
              <Hidden lgUp>
                  <Box
                    p={2}
                    display="flex"
                    justifyContent="center"
                  >
                      <RouterLink to="/">
                          <Logo logo={logo} />
                      </RouterLink>
                  </Box>
              </Hidden>
              <Box p={2}>
                  <Box
                    display="flex"
                    justifyContent="center"
                  >
                      <Avatar alt="User" className={classes.avatar} component={RouterLink} to="/account">
                          {getInitials(user?.fullName)}
                      </Avatar>
                  </Box>
                  <Box
                    mt={2}
                    textAlign="center"
                  >
                      <Link
                        component={RouterLink}
                        to="/account"
                        variant="h5"
                        color="textPrimary"
                        underline="none"
                      >
                          {`${user?.fullName || EMPTY_STRING}`}
                      </Link>
                      <Typography
                        color="textPrimary"
                        variant="body1"
                      >
                          {user?.role.name}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                          {user?.role.description}
                      </Typography>
                  </Box>
              </Box>
              <Divider />
              <Box p={2}>
                  {getNavigationConfiguration().map((config) => (
                      <List
                        key={config.subheader}
                        subheader={(
                            <ListSubheader
                              disableGutters
                              disableSticky
                            >
                                {config.subheader}
                            </ListSubheader>
                        )}
                      >
                          {renderNavItems(config.items, location.pathname, 0)}
                      </List>
                  ))}
              </Box>
          </PerfectScrollbar>
      </Box>
  );

  return (
      <>
          <Hidden lgUp>
              <Drawer
                anchor="left"
                classes={{ paper: classes.mobileDrawer }}
                onClose={onMobileClose}
                open={openMobile}
                variant="temporary"
              >
                  {content}
              </Drawer>
          </Hidden>
          <Hidden mdDown>
              <Drawer
                anchor="left"
                classes={{ paper: classes.desktopDrawer }}
                open
                variant="persistent"
              >
                  {content}
              </Drawer>
          </Hidden>
      </>
  );
}

export default NavBar;

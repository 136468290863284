import { PowerSearchSynchronousQueryFilters, Tag } from 'mediascouting-core-ui-common';

import {
  ADD_TO_POWER_SEARCH_BUCKET,
  CREATE_POWER_SEARCH_TAG,
  DELETE_POWER_SEARCH_TAG,
  PowerSearchBucketUpdate,
  SET_POWER_SEARCH_PARAMS,
  SET_POWER_SEARCH_TAGS,
  UPDATE_POWER_SEARCH_TAG,
} from '../types/PowerSearchActionTypes';

export const setPowerSearchParams = (params: PowerSearchSynchronousQueryFilters) => ({
  type: SET_POWER_SEARCH_PARAMS,
  payload: params,
} as const);

export const addToPowerSearchBucket = (powerSearchBucketUpdate: PowerSearchBucketUpdate) => ({
  type: ADD_TO_POWER_SEARCH_BUCKET,
  payload: powerSearchBucketUpdate,
} as const);

export const setPowerSearchTags = (tags: Array<Tag>) => ({
  type: SET_POWER_SEARCH_TAGS,
  payload: tags,
} as const);

export const createPowerSearchTag = (tag: Tag) => ({
  type: CREATE_POWER_SEARCH_TAG,
  payload: tag,
} as const);

export const updatePowerSearchTag = (id: number, tag: Tag) => ({
  type: UPDATE_POWER_SEARCH_TAG,
  payload: tag,
} as const);

export const deletePowerSearchTag = (id: number) => ({
  type: DELETE_POWER_SEARCH_TAG,
  payload: id,
} as const);

export type PowerSearchActions =
    | ReturnType<typeof setPowerSearchParams>
    | ReturnType<typeof addToPowerSearchBucket>
    | ReturnType<typeof setPowerSearchTags>
    | ReturnType<typeof createPowerSearchTag>
    | ReturnType<typeof updatePowerSearchTag>
    | ReturnType<typeof deletePowerSearchTag>

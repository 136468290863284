import React, { useCallback, useEffect } from 'react';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { createBrowserHistory } from 'history';
import { createStyles, makeStyles, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ErrorBoundary } from 'react-error-boundary';
import {
  EMPTY_STRING,
  initializePersistedFeedbackTypes,
  LogoTypeEnum,
  saveLogo,
  useErrorBoundaryThrow,
  useInitialCall,
} from 'mediascouting-core-ui-common';
import { useDispatch } from 'react-redux';
import theme from './theme';
import Routes from './Routes';
import './intl/index';
import ErrorFallback from './components/error/ErrorFallback';
import Auth from './components/auth/Auth';
import FeedbackCentralAgent from './components/system/FeedbackCentralAgent';
import getUserLogo from './remote/Logo';
import { AppDispatch } from './redux/store';
import Configuration from './configuration/Configuration';

const history = createBrowserHistory();

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
  },
}));

const AVAILABLE_LOGOS = [LogoTypeEnum.LOGIN, LogoTypeEnum.ADMIN, LogoTypeEnum.DEFAULT];

function App(): JSX.Element {
  useStyles();
  const { handleOnError } = useErrorBoundaryThrow();
  const dispatch: AppDispatch = useDispatch();

  const handleErrorBoundaryError = useCallback((error) => {
    dispatch(handleOnError(error));
  }, [dispatch, handleOnError]);

  const getLogo = useCallback((logoType: LogoTypeEnum) => dispatch(getUserLogo(logoType))
    .then((logoData) => {
      dispatch(saveLogo(logoData, logoType));
      return logoData;
    })
    .catch((error) => {
      dispatch(saveLogo(EMPTY_STRING, logoType));
      return Promise.reject(error);
    }), [dispatch]);

  const handleGetAllLogos = useCallback(() => {
    AVAILABLE_LOGOS.forEach((logoToRequest) => {
      getLogo(logoToRequest);
    });
  }, [getLogo]);

  useEffect(() => {
    handleGetAllLogos();
  }, [handleGetAllLogos]);

  useInitialCall({
    callback: () => {
      dispatch(initializePersistedFeedbackTypes(Configuration.schema.logging.activeFeedbackTypes));
    },
    condition: !!Configuration.schema,
  });

  return (
      <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={2}>
                  <Router history={history}>
                      <ErrorBoundary
                        onError={handleErrorBoundaryError}
                        fallbackRender={({ error, resetErrorBoundary }): JSX.Element => (
                            <ErrorFallback onReset={resetErrorBoundary} />
                        )}
                      >
                          <Auth>
                              <FeedbackCentralAgent />
                              <Routes />
                          </Auth>
                      </ErrorBoundary>
                  </Router>
              </SnackbarProvider>
          </MuiPickersUtilsProvider>
      </ThemeProvider>
  );
}

export default App;

import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  all,
  getRoleBaseAccessControlRoutes,
  hasPermission,
  PlatformRoute,
  UserPermission,
} from 'mediascouting-core-ui-common';
import { useSelector } from 'react-redux';
import LoadingScreen from './components/common/LoadingScreen';
import AdminLayout from './layouts/AdminLayout';
import GuestGuard from './components/auth/GuestGuard';
import AuthGuard from './components/auth/AuthGuard';
import { ReduxState } from './redux/reducers';
import TemplateEditorLayout from './layouts/TemplateEditorLayout';

const routesConfig: Array<PlatformRoute> = [
  {
    exact: true,
    path: '/',
    component: (): JSX.Element => <Redirect to="/overview/dashboard" />,
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/Error404')),
  },
  {
    path: '/auth',
    guard: GuestGuard,
    routes: [
      {
        exact: true,
        path: '/auth/login',
        component: lazy(() => import('./views/other/Login')),
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />,
      },
    ],
  },
  {
    exact: true,
    path: '/management/templates/edit/:id',
    when: hasPermission(UserPermission.OWN_TEMPLATES),
    layout: TemplateEditorLayout,
    component: lazy(() => import('./views/management/TemplateIDE')),
  },
  {
    path: '*',
    guard: AuthGuard,
    layout: AdminLayout,
    routes: [
      {
        exact: true,
        path: '/overview/dashboard',
        component: lazy(() => import('./views/other/Overview')),
      },
      {
        exact: true,
        path: '/management/users',
        when: hasPermission(UserPermission.READ_USERS),
        component: lazy(() => import('./views/management/Users')),
      },
      {
        path: '/management/users/:id/:tab',
        exact: true,
        when: all(
          hasPermission(UserPermission.READ_USERS),
          hasPermission(UserPermission.UPDATE_USERS),
        ),
        component: lazy(() => import('./views/management/Users/UserOverview')),
      },
      {
        path: '/management/users/create',
        exact: true,
        when: hasPermission(UserPermission.CREATE_USERS),
        component: lazy(() => import('./views/management/Users/UserCreate')),
      },
      {
        path: '/management/groups/:id/:tab',
        exact: true,
        when: all(hasPermission(UserPermission.READ_GROUPS)),
        component: lazy(() => import('./views/management/Groups/GroupOverview')),
      },
      {
        exact: true,
        path: '/management/groups',
        when: hasPermission(UserPermission.READ_GROUPS),
        component: lazy(() => import('./views/management/Groups')),
      },
      {
        exact: true,
        path: '/management/groups/create',
        when: hasPermission(UserPermission.CREATE_GROUPS),
        component: lazy(() => import('./views/management/Groups/CreateGroup')),
      },
      {
        exact: true,
        path: '/management/queries',
        when: hasPermission(UserPermission.READ_QUERY),
        component: lazy(() => import('./views/management/Queries')),
      },
      {
        path: '/management/markets/groups',
        exact: true,
        component: lazy(() => import('./views/management/MarketGroups')),
      },
      {
        exact: true,
        path: '/management/markets/groups/create',
        when: hasPermission(UserPermission.CREATE_MARKET_GROUPS),
        component: lazy(() => import('./views/management/MarketGroups/MarketGroupCreate')),
      },
      {
        path: '/management/markets/groups/:id/:tab',
        exact: true,
        component: lazy(() => import('./views/management/MarketGroups/MarketGroupOverview')),
      },
      {
        path: '/management/markets',
        exact: true,
        component: lazy(() => import('./views/management/Markets')),
      },
      {
        exact: true,
        path: '/management/markets/create',
        when: hasPermission(UserPermission.CREATE_MARKETS),
        component: lazy(() => import('./views/management/Markets/MarketCreate')),
      },
      {
        path: '/management/markets/:id',
        exact: true,
        component: lazy(() => import('./views/management/Markets/MarketOverview')),
      },
      {
        path: '/management/feeds',
        exact: true,
        when: all(
          hasPermission(UserPermission.READ_FEED),
        ),
        component: lazy(() => import('./views/management/Feeds')),
      },
      {
        exact: true,
        path: '/management/feeds/create',
        when: all(
          hasPermission(UserPermission.CREATE_FEED),
        ),
        component: lazy(() => import('./views/management/Feeds/FeedCreate')),
      },
      {
        path: '/management/feeds/:id',
        exact: true,
        when: all(
          hasPermission(UserPermission.READ_FEED),
          hasPermission(UserPermission.UPDATE_FEED),
        ),
        component: lazy(() => import('./views/management/Feeds/FeedUpdate')),
      },
      {
        path: '/management/sources/groups',
        exact: true,
        component: lazy(() => import('./views/management/SourceGroups')),
      },
      {
        exact: true,
        path: '/management/sources/groups/create',
        when: hasPermission(UserPermission.CREATE_SOURCE_GROUPS),
        component: lazy(() => import('./views/management/SourceGroups/SourceGroupCreate')),
      },
      {
        path: '/management/sources/groups/:id/:tab',
        exact: true,
        component: lazy(() => import('./views/management/SourceGroups/SourceGroupOverview')),
      },
      {
        exact: true,
        path: '/management/sources',
        component: lazy(() => import('./views/management/Sources')),
      },
      {
        exact: true,
        path: '/management/sources/:type/create',
        when: hasPermission(UserPermission.CREATE_SOURCES),
        component: lazy(() => import('./views/management/Sources/SourceCreateView')),
      },
      {
        exact: true,
        path: '/management/sources/:id',
        component: lazy(() => import('./views/management/Sources/SourceOverview')),
      },
      {
        exact: true,
        path: '/management/alerts',
        when: hasPermission(UserPermission.OWN_ALERTS),
        component: lazy(() => import('./views/management/Alerts')),
      },
      {
        exact: true,
        path: '/management/alerts/create',
        when: hasPermission(UserPermission.OWN_ALERTS),
        component: lazy(() => import('./views/management/Alerts/AlertCreate')),
      },
      {
        path: '/management/alerts/:id',
        exact: true,
        when: hasPermission(UserPermission.OWN_ALERTS),
        component: lazy(() => import('./views/management/Alerts/AlertOverview')),
      },
      {
        exact: true,
        path: '/management/templates',
        when: hasPermission(UserPermission.OWN_TEMPLATES),
        component: lazy(() => import('./views/management/Templates')),
      },
      {
        exact: true,
        path: '/management/templates/create',
        when: hasPermission(UserPermission.OWN_TEMPLATES),
        component: lazy(() => import('./views/management/Templates/TemplateCreateView')),
      },
      {
        exact: true,
        path: '/management/templates/:id',
        when: hasPermission(UserPermission.OWN_TEMPLATES),
        component: lazy(() => import('./views/management/Templates/TemplateOverview')),
      },

      {
        exact: true,
        path: '/status/grabbers',
        component: lazy(() => import('./views/status/GrabberMonitor')),
      },
      {
        exact: true,
        path: '/status/logging',
        component: lazy(() => import('./views/status/Logging')),
      },
      {
        exact: true,
        path: '/status/support',
        component: lazy(() => import('./views/status/Support')),
      },
      {
        exact: true,
        path: '/account',
        component: lazy(() => import('./views/other/Account')),
      },
      {
        component: (): JSX.Element => <Redirect to="/404" />,
      },
    ],
  },
];

const renderRoutes = (routes): JSX.Element | null => (routes ? (
    <Suspense fallback={<LoadingScreen />}>
        <Switch>
            {routes.map((route, i) => {
              const Guard = route.guard || Fragment;
              const Layout = route.layout || Fragment;
              const Component = route.component;

              const key = route.path ? route.path.concat(i) : ''.concat(i);
              return (
                  <Route
                    key={key}
                    path={route.path}
                    exact={route.exact}
                    render={(props): React.ReactNode => (
                        <Guard>
                            <Layout>
                                {route.routes
                                  ? renderRoutes(route.routes)
                                  : <Component {...props} />}
                            </Layout>
                        </Guard>
                    )}
                  />
              );
            })}
        </Switch>
    </Suspense>
) : null);

function Routes(): JSX.Element | null {
  const user = useSelector((state: ReduxState) => state.auth.user);
  const rbacRoutes = getRoleBaseAccessControlRoutes(user, routesConfig);

  return renderRoutes(rbacRoutes);
}

export default Routes;

import {
  Group, NOT_FOUND, RoleBasedAccessControl, User,
} from 'mediascouting-core-ui-common';
import { AuthActions } from '../../constants/actions/creators/AuthActions';
import {
  SESSION_LOGIN, SESSION_LOGOUT, USER_DATA, USER_GROUP,
} from '../../constants/actions/types/AuthActionTypes';
import { ACCESS_TOKEN } from '../../constants/LocalStorage';

export interface AuthReducer {
  token?: string;
  user?: User;
  group?: Group;
}

const RBAC_INIT_STATE: RoleBasedAccessControl = {
  ACCESS_ADMIN_PLATFORM: false,
  ACCESS_PORTAL_PLATFORM: false,
  OWN_ALERTS: false,
  OWN_TEMPLATES: false,
  CREATE_GROUP_TEMPLATES: false,
  UPDATE_GROUP_TEMPLATES: false,
  DELETE_GROUP_TEMPLATES: false,
  READ_GROUPS: false,
  CREATE_GROUPS: false,
  UPDATE_GROUPS: false,
  DELETE_GROUPS: false,
  READ_SUB_GROUPS: false,
  CREATE_SUB_GROUPS: false,
  UPDATE_SUB_GROUPS: false,
  DELETE_SUB_GROUPS: false,
  MANAGE_SOFT_DELETED_GROUPS: false,
  CREATE_MARKETS: false,
  UPDATE_MARKETS: false,
  DELETE_MARKETS: false,
  READ_ROLES: false,
  CREATE_ROLES: false,
  UPDATE_ROLES: false,
  DELETE_ROLES: false,
  READ_QUERY: false,
  CREATE_QUERY: false,
  UPDATE_QUERY: false,
  DELETE_QUERY: false,
  OWN_QUERY: false,
  READ_QUERY_SHARES: false,
  CREATE_QUERY_SHARES: false,
  UPDATE_QUERY_SHARES: false,
  DELETE_QUERY_SHARES: false,
  READ_TAG_SHARES: false,
  CREATE_TAG_SHARES: false,
  UPDATE_TAG_SHARES: false,
  DELETE_TAG_SHARES: false,
  CREATE_SOURCES: false,
  UPDATE_SOURCES: false,
  DELETE_SOURCES: false,
  OWN_TAGS: false,
  READ_USER_GROUPS: false,
  CREATE_USER_GROUPS: false,
  DELETE_USER_GROUPS: false,
  READ_SUB_GROUP_USER_GROUPS: false,
  CREATE_SUB_GROUP_USER_GROUPS: false,
  DELETE_SUB_GROUP_USER_GROUPS: false,
  READ_USERS: false,
  CREATE_USERS: false,
  UPDATE_USERS: false,
  DELETE_USERS: false,
  READ_SUB_GROUP_USERS: false,
  UPDATE_SUB_GROUP_USERS: false,
  DELETE_SUB_GROUP_USERS: false,
  MANAGE_SOFT_DELETED_USERS: false,
  INITIATE_DATA_EXPORT_TASK: false,
  READ_SETTING: false,
  CREATE_SETTING: false,
  UPDATE_SETTING: false,
  DELETE_SETTING: false,
};

const accessToken = localStorage.getItem(ACCESS_TOKEN);

const INIT_STATE: AuthReducer = {
  token: (accessToken !== null) ? accessToken : NOT_FOUND,
  user: NOT_FOUND,
};

export default (state = INIT_STATE, action: AuthActions): AuthReducer => {
  switch (action.type) {
    case SESSION_LOGIN: {
      return {
        ...state,
        token: action.payload.token,
        user: state.user,
      };
    }
    case SESSION_LOGOUT: {
      return {
        ...state,
        token: NOT_FOUND,
        user: NOT_FOUND,
      };
    }
    case USER_DATA: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case USER_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    default:
      return state;
  }
};

import React from 'react';
import { UserPermission } from 'mediascouting-core-ui-common';
import DashboardIcon from '@material-ui/icons/Dashboard';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import Configuration from '../../../../configuration/Configuration';

export interface NavConfigItem {
    title: string;
    href: string;
    icon?: React.ReactNode;
    permissions: Array<UserPermission>;
    items?: Array<NavConfigItem>;
    info?: React.ReactNode;
}

export interface NavigationConfig {
    subheader: string;
    items: Array<NavConfigItem>;
}

const getOverviewConfigurations = (): NavigationConfig => {
  const overview = {
    subheader: 'Overview',
    items: [
      {
        title: 'Dashboard',
        href: '/overview/dashboard',
        icon: (<DashboardIcon fontSize="small" />),
        permissions: [],
      },
    ],
  };

  return overview;
};

const getManagementConfigurations = (): NavigationConfig => {
  const management = {
    subheader: 'Management',
    items: [
      {
        title: 'Users',
        href: '/management/users',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.READ_USERS],
      },
      {
        title: 'Groups',
        href: '/management/groups',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.READ_GROUPS],
      },
      {
        title: 'Queries',
        href: '/management/queries',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.READ_QUERY],
      },
      {
        title: 'Sources',
        href: '/management/sources',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [],
      },
      {
        title: 'Source Groups',
        href: '/management/sources/groups',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [],
      },
      {
        title: 'Markets',
        href: '/management/markets',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [],
      },
      {
        title: 'Market Groups',
        href: '/management/markets/groups',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [],
      },
      {
        title: 'Alerts',
        href: '/management/alerts',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.OWN_ALERTS],
      },
      {
        title: 'Templates',
        href: '/management/templates',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.OWN_TEMPLATES],
      },
      {
        title: 'Feeds',
        href: '/management/feeds',
        icon: (<InfoIcon fontSize="small" />),
        permissions: [UserPermission.READ_FEED],
      },
    ],
  };

  return management;
};

const getStatusConfigurations = (): NavigationConfig => {
  const status: NavigationConfig = {
    subheader: 'Status',
    items: [],
  };
  const logging: NavConfigItem = {
    title: 'Logging & Reports',
    href: '/status/logging',
    icon: (<HelpIcon fontSize="small" />),
    permissions: [],
  };
  const support: NavConfigItem = {
    title: 'Support',
    href: '/status/support',
    icon: (<HelpIcon fontSize="small" />),
    permissions: [],
  };

  if (Configuration?.graylogAddress) {
    status.items.push(logging);
  }

  if (Configuration?.redmineAddress) {
    status.items.push(support);
  }

  return status;
};

const getNavigationConfiguration = (): Array<NavigationConfig> => {
  const configurations = [
    getOverviewConfigurations(),
    getManagementConfigurations(),
  ];

  if (Configuration?.graylogAddress || Configuration?.redmineAddress) {
    configurations.push(
      getStatusConfigurations(),
    );
  }

  return configurations;
};

export default getNavigationConfiguration;

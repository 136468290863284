import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUser, logoutAfterExpiredAccess } from '../../remote/Auth';
import authService from '../../services/authService';
import SplashScreen from '../common/SplashScreen';
import { AppDispatch } from '../../redux/store';

type AuthPropTypes = {
  children: React.ReactNode;
};

function Auth(props: AuthPropTypes): JSX.Element {
  const { children } = props;
  const dispatch: AppDispatch = useDispatch();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async (): Promise<void> => {
      authService.setAxiosInterceptors({
        onLogout: () => dispatch(logoutAfterExpiredAccess()),
      });

      authService.handleAuthentication();

      if (authService.isAuthenticated()) {
        await dispatch(getUser())
          .finally(() => {
            setLoading(false);
          });
      }

      setLoading(false);
    };

    initAuth();
  }, [dispatch]);

  if (isLoading) {
    return <SplashScreen />;
  }

  return <>{children}</>;
}

export default Auth;

import React, { memo } from 'react';
import { Box, Typography } from '@material-ui/core';

function EmptyScreen(): JSX.Element {
  return (
      <Box p={2}>
          <Typography variant="h6" color="textPrimary">
              There are no notifications
          </Typography>
      </Box>
  );
}

export default memo(EmptyScreen);

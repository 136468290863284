import { NullableNumber } from 'mediascouting-core-ui-common';

export const NEXT_USER_WIZARD_STEP = 'NEXT_USER_WIZARD_STEP';
export const PREVIOUS_USER_WIZARD_STEP = 'PREVIOUS_USER_WIZARD_STEP';
export const COMPLETE_USER_WIZARD = 'COMPLETE_USER_WIZARD';
export const SAVE_WIZARD_USER_GROUP_AND_ROLE = 'SAVE_WIZARD_USER_GROUP_AND_ROLE';
export const SAVE_WIZARD_USER_DETAILS = 'SAVE_WIZARD_USER_DETAILS';
export const USER_WIZARD_FAILURE = 'USER_WIZARD_FAILURE';

export type UserGroupRolePayload = {
    groupId: NullableNumber;
    roleId: NullableNumber;
}

export type UserDetailsPayload = {
    username: string;
    email: string;
    fullName: string;
    password: string;
}

import { ReportOptionsEnum } from 'mediascouting-core-ui-common';
import {
  AlertConfigurationSchema, ConfigurationSchema, FeedsConfigurationSchema, LoggingSchema,
} from '../../schema';

const PRESSDATA_ALERT_CONFIGURATION: AlertConfigurationSchema = {
  reportOptions: {
    disabled: [
      ReportOptionsEnum.PRODUCE_EXTERNAL_LINKS,
      ReportOptionsEnum.REMOVE_LIVE_QUERY_GROUP,
    ],
    defaults: {
      [ReportOptionsEnum.REMOVE_LIVE_QUERY_GROUP]: true,
    },
  },
};

const PRESSDATA_LOGGING_CONFIGURATION_SCHEMA: LoggingSchema = {
  activeFeedbackTypes: [
  ],
};

const PRESSDATA_FEEDS_CONFIGURATION: FeedsConfigurationSchema = {
  netFeeder: {
    metadata: {
      platformOptions: {
        disabled: [],
      },
    },
  },
};

const PRESSDATA_USER_INTERFACE_CONFIGURATION_SCHEMA: ConfigurationSchema = {
  alerts: PRESSDATA_ALERT_CONFIGURATION,
  logging: PRESSDATA_LOGGING_CONFIGURATION_SCHEMA,
  feeds: PRESSDATA_FEEDS_CONFIGURATION,
};

export default PRESSDATA_USER_INTERFACE_CONFIGURATION_SCHEMA;
